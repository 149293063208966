import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./FlutterExpert.module.scss"

const FlutterExpert = ({ strapiData }) => {
  return (
    <div className={styles.flutterExpert}>
      <Container>
        <div className={styles.flexWrapper}>
          <h2
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div>
            <Link
              to={strapiData?.buttons[0]?.url}
              className={`btn_black_border  ${styles.btn}`}
            >
              {strapiData?.buttons[0]?.title}
            </Link>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default FlutterExpert
