import React from "react"
import * as styles from "./UnlockSuccess.module.scss"

const UnlockSuccessBox = ({ Image, Image2, Heading, Desc }) => {
  return (
    <div className={styles.flutterboxContainer}>
      <div className="d-flex">
        <div>
          <div className={styles.imgWrapper}>
            <img
              className={styles.enhanceIcon}
              decoding="async"
              loading="lazy"
              src={Image}
              alt="potential enhancement"
            />
            <img
              className={styles.hoverEffect}
              src={Image2}
              decoding="async"
              loading="lazy"
              alt="potential enhancement"
            />
          </div>
        </div>
        <div className={`${styles.content} pl-3`}>
          <h3 className={styles.boxHeading}>{Heading}</h3>
          <div className={styles.boxDesc}>{Desc}</div>
        </div>
      </div>
    </div>
  )
}

export default UnlockSuccessBox
