import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Roadmap.module.scss"

const RoadMap = ({ strapiData }) => {
  return (
    <div className={styles.roadMap}>
      <Container>
        <div>
          <h2
            className={styles.headingWrap}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />

          <div
            dangerouslySetInnerHTML={{
              __html: strapiData?.description?.description,
            }}
          ></div>
        </div>
        <Row className="justify-content-center py-5">
          {strapiData?.cards?.map((e, i) => (
            <Col md={2} className={`${styles.columnWrapper} my-3`} key={i}>
              <div className={styles.imageWrapper2}>
                {e?.image1 && (
                  <img
                    decoding="async"
                    loading="lazy"
                    className={`${
                      i === 0
                        ? styles.blue
                        : i === 1
                        ? styles.grey
                        : i === 2
                        ? styles.orange
                        : i === 3
                        ? styles.purple
                        : i === 4
                        ? styles.green
                        : ""
                    }`}
                    src={e?.image1[0].localFile?.publicURL}
                    alt="roadmap"
                    width="100%"
                    height="auto"
                  />
                )}
                {e?.image2 && (
                  <img
                    decoding="async"
                    loading="lazy"
                    className={
                      i === 0
                        ? styles.blue2
                        : i === 1
                        ? styles.grey2
                        : i === 2
                        ? styles.orange2
                        : i === 3
                        ? styles.purple2
                        : i === 4
                        ? styles.green2
                        : ""
                    }
                    src={e?.image2[0].localFile?.publicURL}
                    alt="roadmap"
                    width="100%"
                    height="auto"
                  />
                )}
              </div>
              <h3 className={styles.head}>{e?.title}</h3>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default RoadMap
