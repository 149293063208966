import React from "react"
import Container from "react-bootstrap/Container"
import leftArr from "../../images/svg/leftArr.svg"
import rightArr from "../../images/svg/rightArr.svg"
import "swiper/css"
import { Swiper, SwiperSlide, useSwiper } from "swiper/react"
import * as styles from "./sliderNode.module.scss"
import "../common/nodejs/slider.scss"

const SwiperButtonNext = () => {
  const swiper = useSwiper()
  return (
    <div
      className={styles.portfolioArrowIconCover}
      onClick={() => swiper.slideNext()}
    >
      <img
        src={rightArr}
        onClick={() => swiper.slideNext()}
        className={styles.portfolioArrowIcon}
        alt="arrow"
      />
    </div>
  )
}
const SwiperButtonPrev = () => {
  const swiper = useSwiper()
  return (
    <div
      className={styles.portfolioArrowRightIconCover}
      onClick={() => swiper.slidePrev()}
    >
      <img
        src={leftArr}
        onClick={() => swiper.slidePrev()}
        className={styles.portfolioArrowIconleft}
        alt="arrow"
      />
    </div>
  )
}
const SliderNode = ({ strapiData, flutter }) => {
  return (
    <div className={styles.sliderHead}>
      <Container>
        {flutter ? (
          <>
            <div>
              <h2>
                <span className="text-primary pr-2">InvoZone’s Victory</span>
                Flutter Projects
              </h2>
              <div>Our impactful work speaks for itself</div>
            </div>
          </>
        ) : (
          <>
            <div
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: strapiData?.description?.description,
              }}
            />{" "}
          </>
        )}

        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          initialSlide={1}
          className="pt-4"
          centeredSlides={true}
          breakpoints={{
            768: {
              slidesPerView: 2,
              initialSlide: 0,
            },
            992: {
              slidesPerView: 3,
              initialSlide: 3,
              centerSlides: false,
            },
            1280: {
              slidesPerView: 3,
              initialSlide: 3,
              centerSlides: false,
            },
            1366: {
              slidesPerView: 4,
              initialSlide: 4,
            },
          }}
        >
          <div className={styles.mainContainer}>
            <div className={styles.arrowContainer}>
              <SwiperButtonPrev />
              <SwiperButtonNext />
            </div>
          </div>
          {strapiData?.cards &&
            strapiData?.cards?.map((e, i) => (
              <SwiperSlide className="idSwiper" key={i}>
                {({ isActive }) => (
                  <div
                    className={`${styles.card} ${
                      isActive ? "activeSlider" : ""
                    }`}
                  >
                    <div className={styles.imageContainer}>
                      {/* {e?.image2 && (
                        <img
                          decoding="async"
                          loading="lazy"
                          alt="main"
                          src={
                            isActive ? "" : e?.image2[0]?.localFile?.publicURL
                          }
                          className={`${
                            isActive
                              ? styles.imageActive
                              : styles.imageNotActive
                          }`}
                        />
                      )} */}
                      {e?.image1 && (
                        <img
                          decoding="async"
                          loading="lazy"
                          src={e?.image1[0].localFile.publicURL}
                          className={styles.mainImage}
                          alt={e?.title}
                        />
                      )}
                    </div>
                    {isActive && (
                      <>
                        <p className={styles.innerText}>{e?.title}</p>
                        <div
                          className={styles.cardBody}
                          dangerouslySetInnerHTML={{
                            __html: e?.description?.description,
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
              </SwiperSlide>
            ))}
        </Swiper>
      </Container>
    </div>
  )
}

export default SliderNode
