// extracted by mini-css-extract-plugin
export var boxDesc = "UnlockSuccess-module--boxDesc--eb31a";
export var boxHeading = "UnlockSuccess-module--boxHeading--34917";
export var columnWrapper = "UnlockSuccess-module--columnWrapper--752f8";
export var content = "UnlockSuccess-module--content--8c19e";
export var enhanceIcon = "UnlockSuccess-module--enhanceIcon--0bb01";
export var flexWrapper = "UnlockSuccess-module--flexWrapper--dc6a6";
export var flutterboxContainer = "UnlockSuccess-module--flutterboxContainer--3c01a";
export var headingWrap = "UnlockSuccess-module--headingWrap--dfd9d";
export var hoverEffect = "UnlockSuccess-module--hoverEffect--c4518";
export var iconWrapper = "UnlockSuccess-module--iconWrapper--0656a";
export var iconWrapper2 = "UnlockSuccess-module--iconWrapper2--f3038";
export var imgWrapper = "UnlockSuccess-module--imgWrapper--f853c";
export var languageGitWrapper = "UnlockSuccess-module--languageGitWrapper--291fb";
export var mainHeading = "UnlockSuccess-module--mainHeading--5a0e1";
export var nativeFramework = "UnlockSuccess-module--nativeFramework--59c51";
export var square2Icon = "UnlockSuccess-module--square2Icon--81e9c";
export var squareIcon = "UnlockSuccess-module--squareIcon--d48c8";
export var subHeading = "UnlockSuccess-module--subHeading--1f855";
export var triangleIcon = "UnlockSuccess-module--triangleIcon--4c990";
export var unlockSuccess = "UnlockSuccess-module--unlockSuccess--c0294";