// extracted by mini-css-extract-plugin
export var blue = "Roadmap-module--blue--705c7";
export var blue2 = "Roadmap-module--blue2--ac36a";
export var columnWrapper = "Roadmap-module--columnWrapper--d73b8";
export var green = "Roadmap-module--green--fb885";
export var green2 = "Roadmap-module--green2--50c14";
export var grey = "Roadmap-module--grey--795a7";
export var grey2 = "Roadmap-module--grey2--8cedd";
export var head = "Roadmap-module--head--89085";
export var headingWrap = "Roadmap-module--headingWrap--ae4ab";
export var imageWrapper2 = "Roadmap-module--imageWrapper2--72cc4";
export var orange = "Roadmap-module--orange--8db03";
export var orange2 = "Roadmap-module--orange2--a9666";
export var purple = "Roadmap-module--purple--79ccd";
export var purple2 = "Roadmap-module--purple2--b7e45";
export var roadMap = "Roadmap-module--roadMap--64c91";