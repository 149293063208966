import React from "react"
import Container from "react-bootstrap/Container"
import PartnerCard from "./PartnerCard"
import * as styles from "./PartnerFlutter.module.scss"

const PartnerFlutter = ({ strapiData }) => {
  return (
    <section className={styles.partnerFlutter}>
      <Container>
        <div className={styles.mainHeading}>
          <h2
            className={styles.headingWrap}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />

          <div
            className={styles.subHeading}
            dangerouslySetInnerHTML={{
              __html: strapiData?.description?.description,
            }}
          />
        </div>
        <div className={styles.flexWrapper}>
          <div className={styles.languageGitWrapper}>
            {strapiData?.cards?.map(
              (e, i) =>
                i < 2 && (
                  <div key={i}>
                    <PartnerCard
                      title={e?.title}
                      title2={e?.subTitle}
                      desc={
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: e?.description?.description,
                            }}
                          />
                        </>
                      }
                    />
                  </div>
                )
            )}
          </div>
          <div>
            <img
              className={styles.partnerFlutter}
              src={strapiData?.secImages[0]?.localFile?.publicURL}
              alt="partner img"
              width="100%"
              decoding="async"
              loading="lazy"
              height="auto"
            />
          </div>
          <div className={styles.nativeFramework}>
            {strapiData?.cards?.map(
              (e, i) =>
                i > 1 && (
                  <div key={i}>
                    <PartnerCard
                      style={{ textAlign: "left" }}
                      title={e?.title}
                      title2={e?.subTitle}
                      desc={
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: e?.description?.description,
                            }}
                          />
                        </>
                      }
                    />
                  </div>
                )
            )}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default PartnerFlutter
