import { graphql } from "gatsby"
import React from "react"
import EngagementModels from "../components/common/Engagement-Model/EngagementModels"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import AppDevelopment from "../components/hire-flutter-developer/AppDevelopment"
import Banner from "../components/hire-flutter-developer/Banner"
import FlutterExpert from "../components/hire-flutter-developer/FlutterExpert"
import HireFlutterTeam from "../components/hire-flutter-developer/HireFlutterTeam"
import PartnerFlutter from "../components/hire-flutter-developer/PartnerFlutter"
import RoadMap from "../components/hire-flutter-developer/Roadmap"
import UnlockSuccess from "../components/hire-flutter-developer/UnlockSuccess"
import SliderNode from "../components/hire-flutter-developer/sliderNode"
import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/hire_flutter_developers_dc0201b6c3.webp"
    />
  )
}
const HireFlutterDeveloper = ({ data }) => {
  const banner = data?.strapiPage?.sections[0]
  const patner = data?.strapiPage?.sections[1]
  const unlock = data?.strapiPage?.sections[2]
  const hire = data?.strapiPage?.sections[3]
  const road = data?.strapiPage?.sections[4]
  const benefits = data?.strapiPage?.sections[5]
  const slider = data?.strapiPage?.sections[6]
  const fluterExp = data?.strapiPage?.sections[7]
  const faqs = data?.strapiPage?.sections[8]
  const app = data?.strapiPage?.sections[9]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={banner} />
      <PartnerFlutter strapiData={patner} />
      <UnlockSuccess strapiData={unlock} />
      <HireFlutterTeam strapiData={hire} />
      <AppDevelopment strapiData={app} />
      <RoadMap strapiData={road} />
      <EngagementModels strapiData={benefits} />
      <SliderNode strapiData={slider} flutter={true} />
      <FlutterExpert strapiData={fluterExp} />
      <Faqs strapiData={faqs} />
    </MainLayout>
  )
}

export const query = graphql`
  query flutter {
    strapiPage(slug: { eq: "flutter" }) {
      sections {
        title
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            localFile {
              publicURL
            }
          }
          image2 {
            localFile {
              publicURL
            }
          }
        }
        secImages {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default HireFlutterDeveloper
