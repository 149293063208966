// extracted by mini-css-extract-plugin
export var arrowContainer = "sliderNode-module--arrowContainer--285d7";
export var card = "sliderNode-module--card--f56ec";
export var cardBody = "sliderNode-module--cardBody--4ef80";
export var description = "sliderNode-module--description--5f4fd";
export var heading = "sliderNode-module--heading--36c47";
export var imageActive = "sliderNode-module--imageActive--57f9d";
export var imageContainer = "sliderNode-module--imageContainer--9d57c";
export var imageNotActive = "sliderNode-module--imageNotActive--94b94";
export var innerText = "sliderNode-module--innerText--84e00";
export var mainContainer = "sliderNode-module--mainContainer--c4702";
export var mainImage = "sliderNode-module--mainImage--51a11";
export var portfolioArrowIcon = "sliderNode-module--portfolioArrowIcon--88c48";
export var portfolioArrowIconCover = "sliderNode-module--portfolioArrowIconCover--70956";
export var portfolioArrowIconleft = "sliderNode-module--portfolioArrowIconleft--eae71";
export var portfolioArrowRightIconCover = "sliderNode-module--portfolioArrowRightIconCover--fd5da";
export var sliderHead = "sliderNode-module--sliderHead--608b0";