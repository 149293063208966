import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import MiddlePlus from "../../images/hire-flutter-developer/middlePlus.svg"
import Square2Icon from "../../images/hire-flutter-developer/square2Icon.svg"
import SquareIcon from "../../images/hire-flutter-developer/squareicon.svg"
import Triangle from "../../images/hire-flutter-developer/triangleIcon.svg"
import * as styles from "./UnlockSuccess.module.scss"
import UnlockSuccessBox from "./UnlockSuccessBox"

const UnlockSuccess = ({ strapiData }) => {
  return (
    <div className={styles.unlockSuccess}>
      <img
        decoding="async"
        loading="lazy"
        className={styles.square2Icon}
        src={Square2Icon}
        alt="icon"
      />
      <img
        decoding="async"
        loading="lazy"
        className={styles.triangleIcon}
        src={Triangle}
        alt="Triangle"
      />
      <Container>
        <div className={styles.mainHeading}>
          <h2
            className={styles.headingWrap}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.subHeading}
            dangerouslySetInnerHTML={{
              __html: strapiData?.description?.description,
            }}
          />
        </div>
        <Row className="justify-content-center px-xl-5">
          {strapiData?.cards?.map((e, i) => (
            <Col md={6} className={`${styles.columnWrapper} my-3`} key={i}>
              <UnlockSuccessBox
                Image={e?.image1[0].localFile.publicURL}
                Image2={e?.image2[0].localFile.publicURL}
                Heading={e?.title}
                Desc={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: e?.description?.description,
                    }}
                  />
                }
              />
            </Col>
          ))}
          <div className={styles.iconWrapper}>
            <img decoding="async" loading="lazy" src={MiddlePlus} alt="plus" />
          </div>
          <div className={styles.iconWrapper2}>
            <img decoding="async" loading="lazy" src={MiddlePlus} alt="plus" />
          </div>
        </Row>
      </Container>
      <img
        decoding="async"
        loading="lazy"
        className={styles.squareIcon}
        src={SquareIcon}
        alt="SquareIcon"
      />
    </div>
  )
}

export default UnlockSuccess
