import React from "react"
import * as styles from "./PartnerCard.module.scss"

const PartnerCard = ({ title, title2, desc, style }) => {
  return (
    <div className={`${styles.cardMain} mb-4`} style={style}>
      <h3 className={`${styles.heading} py-2`}>
        <span className="text-primary">{title}</span>
        <div>{title2}</div>
      </h3>
      <div className={styles.desc}>{desc}</div>
    </div>
  )
}

export default PartnerCard
