import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./HireFlutterTeam.module.scss"

const HireFlutterTeam = ({ strapiData }) => {
  return (
    <section className={styles.hireFlutterTeam}>
      <Container>
        <div className={styles.flexWrapper}>
          <div className={styles.leftSection}>
            <h2
              className={styles.mainHeading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            ></h2>
            <div
              className={`${styles.subHeading} pb-4 pt-2 `}
              dangerouslySetInnerHTML={{
                __html: strapiData?.description?.description,
              }}
            />
            <div className="pt-4">
              <Link
                to={strapiData?.buttons[0]?.url}
                className={`btn_white_border ${styles.hireBtn}`}
              >
                {strapiData?.buttons[0]?.title}
              </Link>
            </div>
          </div>
          <div className={styles.rightSection}>
            <img
              decoding="async"
              loading="lazy"
              src={strapiData?.secImages[0]?.localFile?.publicURL}
              alt="Are You Ready To Develop Your Flutter App? "
            />
          </div>
        </div>
      </Container>
    </section>
  )
}

export default HireFlutterTeam
